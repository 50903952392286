const logo = <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16" >
  <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"
  />
  <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z" />
</svg>
export const DataObj = [
  {
    cardName: "card1",
    id: 1,
    img: "images/Angular.webp",
    logo: logo,
    name: "Angular",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card2",
    id: 2,
    img: "images/brand-identity.webp",
    logo: logo,
    name: "Brand Identity",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card3",
    id: 3,
    img: "images/blockchain.webp",
    logo: logo,
    name: "Block Chain",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card4",
    id: 4,
    img: "images/open-source.webp",
    logo: logo,
    name: "Open Source",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card5",
    id: 5,
    img: "images/websitedesign_two1.webp",
    logo: logo,
    name: "Web Designing",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card6",
    id: 6,
    img: "images/websitedesign_two1.webp",
    logo: logo,
    name: "Web Development",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card7",
    id: 7,
    img: "images/nodeJs.webp",
    logo: logo,
    name: "NodeJS",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  },
  {
    cardName: "card8",
    id: 8,
    img: "images/React-js.webp",
    logo: logo,
    name: "ReactJS",
    description: "Escalate the scale of application, embrace parallel development with lesser code and testing readiness on different hierarchical levels.",
  }
]
