import React from "react";
import Cards from "./cards/Cards";
import "./styles.css";

function Insights() {
  const arrOfCardObj = [
    {
      imageSrc: "images/banner_webp_c0bf0317-fa8a-4ea9-a583-5e7a93dfe503.webp",
      title: "Best 10 Benefits of Using Payroll Software",
      description: "by Ashish Nehra | Sep 12, 2022",
      btnText: "View More",
    },
    {
      imageSrc: "images/banner_webp_2a86f2fe-6d27-492f-90f4-58bbe03550aa.webp",
      title: "Latest Trends in Blockchain Technology",
      description: "by Ashish Nehra | Sep 12, 2022",
      btnText: "View More",
    },
    {
      imageSrc: "images/banner_webp_ec9b3dab-9892-4401-a0a1-c83cd0836dbf.webp",
      title: "Common SSL/TLS errors: how to find and fix them",
      description: "by Ashish Nehra | Sep 12, 2022",
      btnText: "View More",
    },
    {
      imageSrc: "images/banner_webp_6115ec98-c50c-4b96-be04-04ff6b880d21.webp",
      title: "MongoDB vs DynamoDB - What Should You Choose?",
      description: "by Ashish Nehra | Sep 12, 2022",
      btnText: "View More",
    },
  ];
  return (
    <div className="container-fluid insights-container  text-light py-5">
      <div className="container text-center">
        <h6>Keep up to the latest news</h6>
        <h4>Insights by GoRun IT Solution</h4>
        <div className="d-flex justify-content-center">
          <hr className="line-divider text-light" />
        </div>
        <p>
          Our initiative on web application
          development to enlighten people about the emerging technological
          advancements & trends in the software industry.
        </p>
        <div className="container">
          <div className="row">
            {arrOfCardObj.map((val,index) => (
              <Cards key={index} val={val} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insights;
