import React, { useState } from "react";
import "./styles.css";

function ServicesUI() {
  const [developementType, setDevelopementType] = useState({
    EcommerceDevelopment: true,
    WebAppDevelopment: false,
    MobileAppDevelopment: false,
    Designing: false,
  });
  function setSelectedStyle() {
    return {
      textDecoration: "underline",
      textDecorationThickness: "8px",
      textUnderlineOffset: "25px",
      color: "#12408c",
    };
  }
  return (
    <div className="dropdown-menu-box-container services-container">
      <div className="container-fluid service-container-fluid">
        <div className="row d-flex justify-content-around categories-row">
          <h5
            className="col-3 text-center"
            onClick={() =>
              setDevelopementType({
                EcommerceDevelopment: true,
                WebAppDevelopment: false,
                MobileAppDevelopment: false,
                Designing: false,
              })
            }
            style={
              developementType.EcommerceDevelopment ? setSelectedStyle() : {}
            }
          >
            <span>E-Commerce Development</span>
          </h5>
          <h5
            className="col-3 text-center web-app-development-category"
            onClick={() =>
              setDevelopementType({
                EcommerceDevelopment: false,
                WebAppDevelopment: true,
                MobileAppDevelopment: false,
                Designing: false,
              })
            }
            style={developementType.WebAppDevelopment ? setSelectedStyle() : {}}
          >
            <span>Web App Development</span>
          </h5>
          <h5
            className="col-3 text-center"
            onClick={() =>
              setDevelopementType({
                EcommerceDevelopment: false,
                WebAppDevelopment: false,
                MobileAppDevelopment: true,
                Designing: false,
              })
            }
            style={
              developementType.MobileAppDevelopment ? setSelectedStyle() : {}
            }
          >
            <span>Mobile App Development</span>
          </h5>
          <h5
            className="col-3 text-center"
            onClick={() =>
              setDevelopementType({
                EcommerceDevelopment: false,
                WebAppDevelopment: false,
                MobileAppDevelopment: false,
                Designing: true,
              })
            }
            style={developementType.Designing ? setSelectedStyle() : {}}
          >
            <span>Designing</span>
          </h5>
        </div>
        {/* web app development component */}
        {developementType.WebAppDevelopment && (
          <div className="row d-flex justify-content-around">
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Web App Services</h6>
              <ul className="list ordered-list">
                <li>
                  <p>LDAP solution</p>
                </li>
                <li>
                  <p>Enterprise Resource Planning</p>
                </li>
                <li>
                  <p>Customer Relation Mangement</p>
                </li>
                <li>
                  <p>Content Management System</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Web App Services</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>LDAP solution</p>
                </li>
                <li>
                  <p>Enterprise Resource Planning</p>
                </li>
                <li>
                  <p>Customer Relation Mangement</p>
                </li>
                <li>
                  <p>Content Management System</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Title</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>LDAP solution</p>
                </li>
                <li>
                  <p>Enterprise Resource Planning</p>
                </li>
                <li>
                  <p>Customer Relation Mangement</p>
                </li>
                <li>
                  <p>Content Management System</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left pt-3">
              <h6 className="column-title">Title</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>LDAP solution</p>
                </li>
                <li>
                  <p>Enterprise Resource Planning</p>
                </li>
                <li>
                  <p>Customer Relation Mangement</p>
                </li>
                <li>
                  <p>Content Management System</p>
                </li>
              </ul>
            </div>
          </div>
        )}
        {/* e commerce development styling */}
        {developementType.EcommerceDevelopment && (
          <div className="row d-flex justify-content-around">
            <div className="col-4 text-center pt-3">
              <h6 className="column-title">Web App Services</h6>
            </div>
            <div className="col-4 text-center pt-3">
              <h6 className="column-title">Web App Services</h6>
            </div>

            <div className="col-3 text-center pt-3">
              <h6 className="column-title">Title</h6>
            </div>
          </div>
        )}

        {/* android app development */}
        {developementType.MobileAppDevelopment && (
          <div className="row d-flex justify-content-around">
            <div className="col-6 text-center pt-3">
              <h6 className="column-title">IOS App Development</h6>
            </div>
            <div className="col-6 text-center pt-3">
              <h6 className="column-title">Android App Development</h6>
            </div>
          </div>
        )}

        {/* Designing */}
        {developementType.Designing && (
          <div className="row d-flex justify-content-around">
            <div className="col-2 text-center pt-3">
              <h6 className="column-title">UI & UX Designing</h6>
            </div>
            <div className="col-2 text-center pt-3">
              <h6 className="column-title">SEO Optimization</h6>
            </div>
            <div className="col-3 text-center pt-3">
              <h6 className="column-title">Enhance UX & Increase Revenue</h6>
            </div>
            <div className="col-3 text-center pt-3">
              <h6 className="column-title">Business Intelligence</h6>
            </div>
            <div className="col-2 text-center pt-3">
              <h6 className="column-title">Color Psychology</h6>
            </div>
          </div>
        )}

          {(developementType.Designing || developementType.EcommerceDevelopment || developementType.MobileAppDevelopment) && <div style={{height:"160px"}}></div>}
        <div className="row consultation-container text-light mt-4">
          <div className="col-11">
            <h4 className="font-weight-bold">GET FREE CONSULTATION</h4>
            <p>
              Want to validate your mobile app idea? Tell us a little bit about
              your requirements.
            </p>
          </div>
          <div className="col-1 text-light">
            <button className="btn consultation-button">CONTACT US</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesUI;
