import React from "react";
import "./styles.css";
import WebDevelopmentComponent from "./components/webDevelopmentComponent/webDevelopmentComponent";
import ResponsiveDesign from "./components/ResponsiveDesign/ResponsiveDesign";

function WebAppDevelopment() {
  const iconProps = {
    height: 80,
    width: 80,
  };
  return (
    <>
      <div className="container-fluid web-app-development-container"></div>
      <div className="container-fluid custom-web-development-company pt-4 text-center">
        <h2 className="">Custom Web Development Company</h2>
        <p>
          We offer easy to scale custom web application development for all
          types of businesses with which companies scan automate their work
          process and enhance customer experience.
        </p>
        <div className="container change pt-4">
          <div className="row">
            <div className="col-sm-6 col-md-4 lchange text-end ">
              <div className="LDAP-solution pb-5 d-flex justify-content-end align-items-center">
                <div className="padding-right">
                  <h4>LDAP Solution</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-lock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                  </svg>
                </span>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-end align-items-center">
                <div className="padding-right">
                  <h4>Enter Resource Planning (ERP)</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-graph-up-arrow"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                    />
                  </svg>
                </span>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-end align-items-center">
                <div className="padding-right">
                  <h4>Customer Relation Management (CRM)</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-hand-thumbs-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                  </svg>
                </span>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-end align-items-center">
                <div className="padding-right">
                  <h4>CMS (Open Source)</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-gear"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 cchange">
              <img
                src="images/web-app-development.webp"
                className="mw-100 h-auto"
                alt="web app development"
              />
            </div>
            <div className="col-sm-6 col-md-4 rchange text-start">
              <div className="LDAP-solution pb-5 d-flex justify-content-start align-items-center">
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-pie-chart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                  </svg>
                </span>
                <div className="padding-left">
                  <h4>Data Analytics</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-start align-items-center">
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-wrench-adjustable"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z" />
                    <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                  </svg>
                </span>
                <div className="padding-left">
                  <h4>On Demand Solution(ERP)</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-start align-items-center">
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-globe"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                  </svg>
                </span>
                <div className="padding-left">
                  <h4>Social Networking</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
              </div>
              <div className="LDAP-solution pb-5 d-flex justify-content-start align-items-center">
                <span className="padding-svg glass-morphism">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconProps.width}
                    height={iconProps.height}
                    fill="currentColor"
                    className="bi bi-ticket"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z" />
                  </svg>
                </span>
                <div className="padding-left">
                  <h4>Event & Tickets</h4>
                  <button className="btn text-light">VIEW MORE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-4 clo-md-6 col-12 text-left  border-in-right">
            <div className="p-5">
              <h4>Multilingual Ready</h4>
              <p>
                Web applications can be made multilingual ready just like
                standalone software. In web application development developers
                can check geographical location or client system’s language
                preference to load the appropriate language version of web
                application.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="col-lg-4 clo-md-6 col-12 text-left  border-in-right">
            <div className="p-5">
              <h4>Extensive Functions</h4>
              <p>
                Usually web applications offer extensive functions and features
                than standalone software because these are easily scalable. The
                responsive design, continuous development and deployment process
                keeps on extending the features of web apps to stay competitive.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="col-lg-4 clo-md-6 col-12 text-left">
            <div className="p-5">
              <h4>Uncluttered Layout</h4>
              <p>
                Creating uncluttered responsive web designs with HTML5, CSS3,
                material design for bootstrap and many more. Following KISS
                (Keep it stupid simple) principle in our web application
                development process to reduce the learning curve for consumers
                at all levels.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 clo-md-6 col-12 text-left  border-in-right block-1 text-light">
            <div className="p-5">
              <p>
                Making Your workplace efficient with custom web applications.
              </p>

              <h4>Describing Decipher Zone's Web App Development Team!</h4>
              <p>
                Our brain storming thought process, improvisation with agile
                scrum principles, extensive experience in java development and
                front end development makes us a frontline custom web
                application development company. Our IT Professional service is
                committed to undertake projects based on web development
                technology competence and know-how abilities. We strive to
                improve your capability, help you achieve your objectives in
                minimum time possible, in a cost effective way and by providing
                a well responsive Quality web applications.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="col-lg-4 clo-md-6 col-12 text-left text-light border-in-right block-2">
            <div className="p-5">
              <h4>Extensive Functions</h4>
              <p>
                Usually web applications offer extensive functions and features
                than standalone software because these are easily scalable. The
                responsive design, continuous development and deployment process
                keeps on extending the features of web apps to stay competitive.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
          <div className="col-lg-4 clo-md-6 col-12 m-bg text-left" style={{background:'url(/images/m-bg.webp)'}}>
            <div className="p-5 text-white">
              <h4>Uncluttered Layout</h4>
              <p>
                Creating uncluttered responsive web designs with HTML5, CSS3,
                material design for bootstrap and many more. Following KISS
                (Keep it stupid simple) principle in our web application
                development process to reduce the learning curve for consumers
                at all levels.
              </p>
              <button className="btn bg-transparent border border-danger d-block">
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>

      <WebDevelopmentComponent/>
      <ResponsiveDesign />
    </>
  );
}

export default WebAppDevelopment;
