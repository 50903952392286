import React from "react";
import "./style.css";

function WebDevelopmentComponent() {
  return (
    <div className="container">
      <div className="web-main">
        <div className="web-heading">
          <h1>
            TAKE YOUR BUSINESS TO NEXT LEVEL WITH{" "}
            <a href="/#">CUSTOM WEB APPLICATION DEVELOPMENT COMPANY</a>
          </h1>
          <div className="dec-line"></div>
          <p>
            Only few web apps are designed good, With us you can make sure
            yours's is one among them.
          </p>
        </div>

        <div className="main-component">
          <div className="items">
            <div className="icon">
              <i className="bi bi-card-checklist"></i>
            </div>
            <div className="details">
              <h4>Web Design & UI</h4>
              <p>
                Our modus operandi is gorgeous web designs in web application
                development with Clean, responsive and user friendly UI which
                plays key role in gaining and retaining customers.
              </p>
            </div>
          </div>

          <div className="items">
            <div className="icon">
              <i className="bi bi-journal-bookmark-fill"></i>
            </div>
            <div className="details">
              <h4>Web Development & CMS</h4>
              <p>
                The Content management system is an inevitable part in modern
                web application development which allows the administrator to
                customize the content and design anytime with ease.
              </p>
            </div>
          </div>

          <div className="items">
            <div className="icon">
              <i className="bi bi-tools"></i>
            </div>
            <div className="details">
              <h4>Easy Installation</h4>
              <p>
                For web applications, the deployment and configuration are done
                on web server by developers but it is a one-time task. The
                updates are patched on the web server so the end user always
                have the latest version to use.
              </p>
            </div>
          </div>

          <div className="items">
            <div className="icon">
              <i className="bi bi-star"></i>
            </div>
            <div className="details">
              <h4>Trusted Expert Support</h4>
              <p>
                Our well versed highly experienced web application developers
                are available round the clock for support. Whether you want
                full-stack, back end, front end developer or a Quality analyst
                you just name it we have it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDevelopmentComponent;
