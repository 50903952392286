import React from 'react'
import './styles.css'

function HomePage({obj}) {
  return (
    <>
    {/* // <div className='container-fluid p-0 position-relative'><img className='w-100 h-auto image-responsive' src='images/banner.jpg' alt="banner"/>
    // <div className='container-fluid p-0 d-flex justify-content-center align-items-center title'>
    //   GoRun - IT Solution
    // </div>
    // </div> */}
     <div id="banner" className="is-loading" >
        {/* <img src={obj.imageSrc} alt="" className='image-responsive' /> */}
        <div className="banner-contant">
            <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 banner text-center">
                    <h1><span>{obj.title}</span></h1>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default HomePage