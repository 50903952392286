import React from "react";
import "./styles.css";

function AboutUs() {
  return (
    <div className="container-fluid pt-5">
      <div className="row d-flex align-items-start">
        <div className="col-lg-6 col-12">
          <img
            src="images/home04-img01.webp"
            className="mw-100 h-auto"
            alt="laptop"
          />
        </div>
        <div className="col-lg-6 col-12">
          <h2>Software Development Company</h2>
          <p>
          Software development company offering agile software development, web
            app development, android
            app development, IOS
            app development, hybrid
            app development, dashboard development
            and integration, blockchain development for creating smart contracts
            based automated systems. Visit GoRun IT Solution today!
          </p>
          <h5>Client Satisfaction Is Our Triumph</h5>
          <p>
            We are a client oriented company which provides the best web app
            development and IT solutions. Our aim is to develop the best
            possible cost effective IT solution for clients to facilitate their
            business operations. We as a software development company ensure that
            our enterprise IT solution is a harbinger for resolving client’s
            problems.
          </p>
          <div className="text-left pt-4">
            <button className="btn btn-info text-light">LEARN MORE</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
