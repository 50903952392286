import React, { useState, useEffect } from "react";
import HomePage from "./HomePage";
import WeSpecializeIn from "../weSpecializeIn";
import AboutUs from "../aboutUs/AboutUs";
// import ExploreDevelopment from "../explore-development/ExploreDevelopment";
import ExploreArea from "../explore-development/ExploreArea";
import ClinchProfits from "../clinchProfits/ClinchProfits";
import Insights from "../insights/Insights";
import { HomePageData } from "../../services/homePage.service";
import "./homePageContainerStyles.css";

function HomePageContainer() {
  const [bannerObjArray, setBannerObjArray] = useState([]);
  const [simpl, setSimpl] = useState('');
  const [bannerObj, setBannerObj] = useState({
    imageSrc: "images/banner.jpg",
    title: "Web Application Development",
    index: 1,
  });
  useEffect(() => {
    HomePageData.setCompanyTitleAndImages().then(res => setBannerObjArray(res))
    HomePageData.simplifyNeeds().then(res => setSimpl(res))
  }, [])
  console.log(simpl)

  function next() {
    for (let i = 0; i < bannerObjArray.length; i++) {
      if (JSON.stringify(bannerObjArray[i]) === JSON.stringify(bannerObj)) {
        let index = bannerObjArray.indexOf(bannerObjArray[i]);
        let newNum = index + 1;
        if (newNum === bannerObjArray.length - 1) {
          index = index + 1;
          setBannerObj(bannerObjArray[index]);
          return;
        } else if (newNum < bannerObjArray.length - 1) {
          index = index + 1;
          setBannerObj(bannerObjArray[index]);
          return;
        } else {
          setBannerObj(bannerObjArray[0]);
          return;
        }
      }
    }
  }
  function prev() {
    for (let i = 0; i < bannerObjArray.length; i++) {
      if (JSON.stringify(bannerObjArray[i]) === JSON.stringify(bannerObj)) {
        let index = bannerObjArray.indexOf(bannerObjArray[i]);
        let newNum = index - 1;
        if (newNum > -1) {
          index = index - 1;
          setBannerObj(bannerObjArray[index]);
        } else {
          setBannerObj(bannerObjArray[bannerObjArray.length - 1]);
        }
      }
    }
  }
  return (
    <React.Fragment>
      <div
        className="container-fluid p-0 homepage-container-styling"
        style={{
          backgroundImage: `url(${bannerObj.imageSrc})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <HomePage obj={bannerObj} />
        <div
          className=" round-arrow-styling left-round-arrow-styling position-absolute"
          onClick={() => prev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="bi bi-chevron-left svg-arrow"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
        <div
          className=" round-arrow-styling right-round-arrow-styling position-absolute"
          onClick={() => next()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-chevron-right svg-arrow"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
      <WeSpecializeIn />
      <AboutUs />
      {/* <ExploreDevelopment/> */}
      <ExploreArea />
      <ClinchProfits />
      <Insights />
    </React.Fragment>
  );
}

export default HomePageContainer;
