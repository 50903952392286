import React from "react";
import "./styles.css";

function Cards({ val }) {
  return (
    <div className="col-lg-3 col-md-6 col-12 py-3">
    <div className="card text-dark" style={{ width: "100%" }}>
      <img className="card-img-top" src={val.imageSrc} alt="Card cap" />
      <div className="card-body">
        <h5 className="card-title">{val.title}</h5>
        <p className="card-description pt-5">{val.description}</p>
        <div className="d-flex justify-content-center line-divider-box">
          <hr className="line-divider text-info" />
        </div>
        <a href="/" className="btn btn-primary">
          {val.btnText}
        </a>
      </div>
    </div>
    </div>
  );
}

export default Cards;
