const HomePageData = {
  setCompanyTitleAndImages: async () => {
    return [
      {
        imageSrc: "images/banner.jpg",
        title: "Web Application Development",
        index: 1,
      },
      {
        imageSrc: "images/home01-banner2.webp",
        title: "Cloud Application Development",
        index: 2,
      },
      {
        imageSrc: "images/home01-banner3.webp",
        title: "Device Application Development",
        index: 3,
      },
    ];
  },
  simplifyNeeds: async() => {
    return "Enhance User Experience And Increase Revenue Adapt new trends in sales, marketing, autonomy, security and enhance user experience.";
  },
};
export { HomePageData };
