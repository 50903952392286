import React, { useState } from "react";
import MobileNavbar from "./mobileNavbar/MobileNavbar";
import AboutUs from "./components/about us/AboutUs";
import ServicesUI from "./components/servicesUI/ServicesUI";
import HireDeveloper from "./components/hire developer/HireDeveloper";
import "./styles.css";

function BottomNavbar() {
  const IconProps = {
    height: 30,
    width: 30,
  };
  const [menuOptionsComponentState, setMenuOptionsComponentState] = useState({
    aboutUs: false,
    Services: false,
    hireDeveloper: false,
    webDevelopment: false,
    blog: false,
    career: false,
    contactUs: false,
  });
  const [drawer, setDrawer] = useState(false);
  return (
    <>
      <div
        className="container-fluid position-fixed bottom-navbar-container"
        onMouseLeave={() =>
          setMenuOptionsComponentState({
            aboutUs: false,
            Services: false,
            hireDeveloper: false,
            webDevelopment: false,
            blog: false,
            career: false,
            contactUs: false,
          })
        }
      >
        <div className="menu-text-container icon-container">
          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: true,
                Services: false,
                hireDeveloper: false,
                webDevelopment: false,
                blog: false,
                career: false,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-house-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                />
              </svg>
            </a>
          </div>
          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: true,
                hireDeveloper: false,
                webDevelopment: false,
                blog: false,
                career: false,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-person-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </a>
          </div>
          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: false,
                hireDeveloper: true,
                webDevelopment: false,
                blog: false,
                career: false,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-door-closed-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </a>
          </div>

          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: false,
                hireDeveloper: false,
                webDevelopment: true,
                blog: false,
                career: false,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-bag-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z" />
              </svg>
            </a>

            {menuOptionsComponentState.webDevelopment && (
              <div className="dropdown-menu-box-container">
                <div
                  className="dropdown-menu-box"
                  aria-labelledby="dropdownMenuButton"
                >
                  <h5 className="text-left icon-title">Web Development</h5>
                </div>
              </div>
            )}
          </div>

          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: false,
                hireDeveloper: false,
                webDevelopment: false,
                blog: true,
                career: false,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-blockquote-left svg-styling"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z" />
              </svg>
            </a>
            {menuOptionsComponentState.blog && (
              <div className="dropdown-menu-box-container">
                <div
                  className="dropdown-menu-box"
                  aria-labelledby="dropdownMenuButton"
                >
                  <h5 className="text-left icon-title">Blog</h5>
                </div>
              </div>
            )}
          </div>
          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: false,
                hireDeveloper: false,
                webDevelopment: false,
                blog: false,
                career: true,
                contactUs: false,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-styling menu-options-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-bag-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z" />
              </svg>
            </a>
            {menuOptionsComponentState.career && (
              <div className="dropdown-menu-box-container">
                <div
                  className="dropdown-menu-box"
                  aria-labelledby="dropdownMenuButton"
                >
                  <h5 className="text-left icon-title">Career</h5>
                </div>
              </div>
            )}
          </div>
          <div
            className="svg-container"
            onMouseEnter={() =>
              setMenuOptionsComponentState({
                aboutUs: false,
                Services: false,
                hireDeveloper: false,
                webDevelopment: false,
                blog: false,
                career: false,
                contactUs: true,
              })
            }
          >
            <a
              href="/"
              className="text-decoration-none menu-options-link menu-options-styling"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={IconProps.width}
                height={IconProps.height}
                fill="currentColor"
                className="bi bi-telephone-fill svg-styling"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </a>
            {menuOptionsComponentState.contactUs && (
              <div className="dropdown-menu-box-container">
                <div className="dropdown-menu-box" aria-labelledby="dropdownMenuButton"                 >
                  <h5 className="text-left icon-title">Contact Us</h5>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="logo-container text-light text-end">
          <img src="images/GoRun.png" alt="logo" className="logo" />
        </div>
        <div
          className="hamburger-bar-container"
          onClick={() => setDrawer((prev) => !prev)}
        >
          <div className="hamburger-bar"></div>
          <div className="hamburger-bar"></div>
          <div className="hamburger-bar"></div>
        </div>
        {menuOptionsComponentState.aboutUs && <AboutUs />}
        {menuOptionsComponentState.Services && <ServicesUI />}
        {menuOptionsComponentState.hireDeveloper && <HireDeveloper />}
      </div>
      {drawer && <MobileNavbar />}
    </>
  );
}

export default BottomNavbar;
