import React from 'react'
import './styles.css'

function AboutUs() {
  return (
    <div className="dropdown-menu-box-container"    >
      <div className="dropdown-menu-box" aria-labelledby="dropdownMenuButton">
        <a className="dropdown-item text-left anchor-link-text" href="/">About Us </a>
        <a className="dropdown-item text-left anchor-link-text" href="/">Our Team</a>
        <a className="dropdown-item text-left anchor-link-text" href="/">Our Work</a>
        <a className="dropdown-item text-left anchor-link-text" href="/">Work Approach</a>
        <a className="dropdown-item text-left anchor-link-text" href="/">Our Development Process</a>
        <h5 className="text-left icon-title">About Us</h5>
      </div>
    </div>
  )
}

export default AboutUs