import React from 'react'
import './styles.css'

function HireDeveloper() {
  return (
    <div className="dropdown-menu-box-container services-container">
      <div className="container-fluid service-container-fluid">
      <div className="row consultation-container text-light mt-4">
          <div className="col-12">
            <h4 className="font-weight-bold text-center">HIRE DEVELOPER</h4>
          </div>
        </div>
        {/* web app development component */}
          <div className="row d-flex justify-content-around">
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Web App Services</h6>
              <ul className="list ordered-list">
                <li>
                  <p>Hire Java Develop</p>
                </li>
                <li>
                  <p>Hire PHP Develop</p>
                </li>
                <li>
                  <p>Hire Magneto Develop</p>
                </li>
                <li>
                  <p>Hire Word Press Develop</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Web App Services</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>Hire Angular Developer</p>
                </li>
                <li>
                  <p>Hire NodeJS Developer</p>
                </li>
                <li>
                  <p>Hire ReactJS Developer</p>
                </li>
                <li>
                  <p>Hire UI & UX Designer</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left column-1 pt-3">
              <h6 className="column-title">Title</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>Hire Full Stack Developer</p>
                </li>
                <li>
                  <p>Hire MEAN Developer</p>
                </li>
                <li>
                  <p>Hire MERN Developer</p>
                </li>
                <li>
                  <p>Hire devOps Engineer</p>
                </li>
              </ul>
            </div>
            <div className="col-3 text-left pt-3">
              <h6 className="column-title">Title</h6>
              <ul className="list ordered-list text-left">
                <li>
                  <p>Hire Iphone Developer</p>
                </li>
                <li>
                  <p>Hire Android Developer</p>
                </li>
                <li>
                  <p>Hire Business Analyst</p>
                </li>
                <li>
                  <p>Hire Quality Analyst</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row consultation-container text-light mt-4">
          <div className="col-11">
            <h4 className="font-weight-bold">GET FREE CONSULTATION</h4>
            <p>
              Want to validate your mobile app idea? Tell us a little bit about
              your requirements.
            </p>
          </div>
          <div className="col-1 text-light">
            <button className="btn consultation-button">CONTACT US</button>
          </div>
        </div>
          </div>
          </div>
  )
}

export default HireDeveloper