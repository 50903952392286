import React from "react";
import "./style.css";

function ResponsiveDesign() {
  return (
    <div
      className="design-container"
      style={{
        backgroundImage: "url(../images/responsive-design.webp)",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className="container">
        <div className="inner-design">
          <div className="design-heading">
            <h1>RESPONSIVE DESIGN</h1>
            <div className="c-icons">
              <span>
                <i className="bi bi-phone"></i>
              </span>
              <span>
                <i className="bi bi-tablet"></i>
              </span>
              <span>
                <i className="bi bi-laptop"></i>
              </span>
            </div>
            <p className="design-para">
              Our website’s theme is 100% ultra-responsive built on cutting-edge
              twitter bootstrap framework, which absolutely makes our site look
              fantastic and work well on any high pixel density devices. You can
              incorporate these features into your web application too. A
              progressive and responsive web app can work as good as a native
              application in most of the cases on a fraction of the cost. Why to
              spend extra bucks on native software applications?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveDesign;
