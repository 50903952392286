import React from "react";
import "./styles.css";

function WeSpecializeIn() {
  return (
    <div className="container-fluid we-specialize-in-container py-5">
      <h1 className="text-center">
        Software Development Company To Simplify Your Needs
      </h1>
      <div className="breifcase-logo-container text-center d-flex align-items-center justify-content-center">
        <hr className="hr-top" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-briefcase"
          viewBox="0 0 16 16"
        >
          <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
        </svg>
        <hr className="hr-bottom" />
      </div>
      <p className="text-center">
        We realize that you are encountering problems in your business ,we
        solemnly work for developing solutions to annihilate your troubles.
      </p>
      <div className="container ">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 border-opacity-75 d-flex align-items-start column-1 pt-4">
            <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Enhance User Experience And Increase Revenue</h4>
            <p>
            Improve the client’s experiences, bring more feature-rich and innovative products to market.
            </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 border-opacity-75 d-flex align-items-start column-2 pt-4">
            <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Data Analysis</h4>
            <p>
            Software development is essential for data analysis. The data collected, when combined with software, can be utilized by businesses to keep a record of the trends among their clients
            </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 d-flex align-items-start column-3 pt-4">
          <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Improves sales and service</h4>
            <p>
            It is important to know how your customers think about your brand and products. Let customers reach out to you easily and share their opinion about your products and services.
            </p>
            </div>
          </div>
        {/* <div className="row"> */}
          <div className="col-lg-4 col-md-6 col-12 d-flex align-items-start pt-4 column-4">
            <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Direct communication</h4>
            <p>
            Software development is the only thing that can help you have direct communication with the clients.
            </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 d-flex align-items-start  pt-4 column-5">
            <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Increase customer engagement</h4>
            <p>
            Boost the customers’ engagement with the help of a mobile app or website and make them come back to you and not to your rival.
            </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 d-flex align-items-start  pt-4 column-6">
            <span className="growth-logo-span">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/>
</svg>
</span>
<div>
            <h4>Helps in marketing your business</h4>
            <p>
            Software development helps to apply on-the-go marketing of your business thus promoting your products and services at any place without extra time required.
            </p>
            </div>
          </div>
          </div>

        {/* </div> */}
      </div>
    </div>
  );
}

export default WeSpecializeIn;
