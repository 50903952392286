import React from "react";
import { Routes, Route } from "react-router-dom";
import BottomNavbar from "./components/bottomNavbar/BottomNavbar";
import { getRoutes } from "./Routes";

function App() {
  return (
    <>
      <Routes>
        {getRoutes.map((val, index) => <Route key={index} path={val.path} element={val.element} />)}
      </Routes>
      <BottomNavbar />
    </>
  );
}

export default App;
