import React, { useState } from "react";
import "./styles.css";
import { DataObj } from "./DataObj";

function ExploreArea() {
  const [dataObj] = useState(DataObj);
  return (
    <div className="main">
      {dataObj.map((elem) => {
        return (
          <div className="card-box" key={elem.id}>
            <div className="inner">
              <img
                className="mw-100 w-100 image-responsive"
                src={elem.img}
                alt="development images"
              />
              <div className="detail">
                <h1 className="card-title">{elem.name}</h1>
              </div>
            </div>
            <div className="slideUp">
              <div className="main-slide">
                <span>
                  {elem.logo}
                </span>
                <h1 className="head">{elem.name}</h1>
                <p className="description">
                  {elem.description}
                </p>
                <div className="explore-btn">
                  <button>explore more</button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default ExploreArea;
